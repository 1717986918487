import React, { FC, useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Avatar, Box, Button, Divider, Drawer, Hidden, Typography } from '@mui/material';
import ContactsIcon from '@mui/icons-material/PeopleOutline';
import PeopleIcon from '@mui/icons-material/PeopleOutline';
import ContactGroupsIcon from '@mui/icons-material/GroupsOutlined';
import AccountCircleIcon from "@mui/icons-material/AccountCircleOutlined";
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import SmsIcon from '@mui/icons-material/SmsOutlined';
import GroupWorkIcon from '@mui/icons-material/GroupWorkOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptLongOutlined';
import SellIcon from '@mui/icons-material/SellOutlined';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import ImportContactsIcon from '@mui/icons-material/ImportContactsOutlined';
import useAuth from 'hooks/useAuth';
import NavSection, { getItems, NavItemModel } from '../NavSection';
import Scrollbar from '../Scrollbar';
import { hasValue } from '../inputs/inputHelpers';
import ContactUsButton from "./ContactUsButton";
import { appPermissions, localRoutes, remoteRoutes, rootAdminRoles } from "../../constants";
import { appSettings } from "../../config";
import MessagingLogo from "../../logos/MessagingLogo";
import { toTitleCase } from "../../utils/stringHelpers";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

type Section = {
  title: string,
  items: NavItemModel[]
};

const sections = (): Section[] => [
  {
    title: 'Analytics',
    items: [
      {
        title: 'Dashboard',
        path: localRoutes.dashboard,
        icon: <DashboardIcon fontSize="small" />
      },
    ]
  },
  {
    title: 'Communication',
    items: [
      {
        title: 'SMS',
        icon: <SmsIcon fontSize="small"/>,
        path: '/',
        roles: [appPermissions.SMS_MESSAGE_TRIGGER, appPermissions.SMS_MESSAGE_VIEW],
        children: [
          {
            title: 'Single SMS',
            path: localRoutes.singleSms
          },
          {
            title: 'Bulk SMS',
            path: localRoutes.bulkSms
          },
        ]
      },
      {
        title: 'Templates',
        icon: <ImportContactsIcon fontSize="small"/>,
        path: localRoutes.smsTemplates,
        roles: [appPermissions.SMS_MESSAGE_TRIGGER, appPermissions.SMS_MESSAGE_VIEW]
      },
    ]
  },
  {
    title: 'General',
    items: [
      {
        title: 'Contacts',
        icon: <ContactsIcon fontSize="small"/>,
        path: localRoutes.contacts,
        roles: [appPermissions.CUSTOMERS_EDIT, appPermissions.CUSTOMERS_VIEW]
      },
      {
        title: 'Contact Groups',
        icon: <ContactGroupsIcon fontSize="small"/>,
        path: localRoutes.contactGroups,
        roles: [appPermissions.CUSTOMER_GROUP_EDIT, appPermissions.CUSTOMER_GROUP_VIEW]
      },
    ]
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Staff',
        icon: <PeopleIcon fontSize="small"/>,
        path: '/staff',
        roles: [appPermissions.PARTNER_MEMBERS_EDIT, appPermissions.PARTNER_MEMBERS_VIEW]
      },
      {
        title: 'Partners',
        icon: <GroupWorkIcon fontSize="small"/>,
        path: '/partners',
        roles: [appPermissions.PARTNERS_EDIT, appPermissions.PARTNERS_VIEW]
      },
      {
        title: 'Settings',
        icon: <SettingsIcon fontSize="small"/>,
        path: '/settings',
        roles: [appPermissions.PARTNER_SETTINGS_EDIT, appPermissions.PARTNER_SETTINGS_VIEW]
      },
      {
        title: 'Invoices',
        icon: <ReceiptIcon fontSize="small"/>,
        path: '/invoices',
        roles: [appPermissions.PARTNERS_VIEW]
      },
      {
        title: 'Price Bands',
        icon: <SellIcon fontSize="small"/>,
        path: '/price-bands',
        roles: [...rootAdminRoles]
      }
    ]
  },
  {
    title: 'Configuration',
    items: [
      {
        title: 'SMS Kannel',
        icon: <SettingsApplicationsIcon fontSize="small"/>,
        path: localRoutes.smsKannel,
        roles: [appPermissions.KANNEL_STATUS_VIEW]
      }
    ]
  },
  {
    title: 'Account',
    items: [
      {
        title: 'Profile',
        path: '/profile',
        icon: <AccountCircleIcon fontSize="small"/>
      },
    ]
  }
];

const DashboardSidebar: FC<React.PropsWithChildren<DashboardSidebarProps>> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const sectionsData = useMemo<Section[]>(() => sections()
    .map((it) => ({ ...it, items: getItems(it.items, user) }))
    .filter((it) => hasValue(it.items)), [user, sections]);

  const content = (
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}
        >
            <Scrollbar options={{ suppressScrollX: true }}>
                <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%'
                    }}
                >
                    <Hidden lgDown>
                        <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'center',
                              p: 2
                            }}
                        >
                            <RouterLink to="/">
                                <MessagingLogo
                                    sx={{
                                      width: 50,
                                      height: 50,
                                    }}
                                />
                            </RouterLink>
                            <Box
                                sx={{
                                  ml: 1
                                }}
                            >
                                <Typography
                                    color="text.primary"
                                    variant="h6"
                                >
                                    {appSettings.appName}
                                </Typography>
                            </Box>
                        </Box>
                    </Hidden>
                    <Box sx={{ px: 2, pb: 2 }}>
                        <Box
                            sx={{
                              alignItems: 'center',
                              backgroundColor: 'background.default',
                              borderRadius: 1,
                              display: 'flex',
                              overflow: 'auto',
                              p: 2
                            }}
                        >
                            <Avatar
                                src={user.avatar}
                                sx={{
                                  cursor: 'pointer',
                                  height: 48,
                                  width: 48
                                }}
                            />
                            <Box sx={{ ml: 2 }}>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    {toTitleCase(user.name)}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {user.email}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Divider/>
                    <Box sx={{ p: 2 }}>
                        {sectionsData.map((section) => (
                            <NavSection
                                key={section.title}
                                pathname={location.pathname}
                                sx={{
                                  '& + &': {
                                    mt: 3
                                  }
                                }}
                                {...section}
                            />
                        ))}
                    </Box>
                    <Box sx={{
                      marginTop: 'auto',
                      width: '100%'
                    }}
                    >
                        <Divider/>
                        <Box
                            sx={{
                              px: 2,
                              py: 3
                            }}
                        >
                            <ContactUsButton title="Contact us"/>
                            <Button
                                color="primary"
                                fullWidth
                                sx={{ mt: 2 }}
                                href={remoteRoutes.apiDocumentation}
                                target="_blank"
                                variant="contained"
                            >
                                API Documentation
                            </Button>

                        </Box>
                    </Box>
                </Box>
            </Scrollbar>
        </Box>
  );

  return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    PaperProps={{
                      sx: {
                        backgroundColor: 'background.paper',
                        width: 280
                      }
                    }}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    open
                    PaperProps={{
                      sx: {
                        backgroundColor: 'background.paper',
                        height: '100%',
                        width: 280,
                        border: 'none'
                      }
                    }}
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
  );
};

export default DashboardSidebar;
