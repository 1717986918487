import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig, getValue } from "firebase/remote-config";
import { initializeApp } from "firebase/app";

export const appSettings = {
  appName: 'Messaging'
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 60000;

remoteConfig.defaultConfig = {
  "enable_iotec_wallet_payment_method": false,
  "max_credits_top_up": 20000000,
  "iotec_bank_name": "Stanbic",
  "iotec_ugx_bank_account_number": "9030020293909",
  "iotec_usd_bank_account_number": "9030020370032",
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const remoteConfigData = {
  enableIotecWalletPaymentMethod: () => {
    return getValue(remoteConfig, 'enable_iotec_wallet_payment_method').asBoolean();
  }
};
